/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
///Images

//Import
import { ThemeContext } from "../../../context/ThemeContext";
import { axiosInstance } from "../../../services/AxiosInstance";

const Home = () => {
  const [count, setCount] = useState(null);
  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);
  const DashboardCount = () => {
    axiosInstance
      .get(`admin/total_count`)
      .then((response) => {
        if (response.data.status) {
          setCount(response?.data?.data);
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    DashboardCount();
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-sm-6">
          <div className="card gradient-1 card-bx">
            <div className="card-body d-flex align-items-center">
              <div className="me-auto text-white">
                <h2 className="text-white">{count?.judges}</h2>
                <span className="fs-18">Judges</span>
              </div>
              <img src="/img/judge.png" width={"45%"} alt="" />
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6">
          <div className="card gradient-2 card-bx">
            <div className="card-body d-flex align-items-center">
              <div className="me-auto text-white">
                <h2 className="text-white">{count?.pop_song}</h2>
                <span className="fs-18">POP ROCK</span>
              </div>
              <img src="/img/POP_ROCK_.png" width={"50%"} alt="" />
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6">
          <div className="card gradient-3 card-bx">
            <div className="card-body d-flex align-items-center">
              <div className="me-auto text-white">
                <h2 className="text-white">{count?.hip_hop_song}</h2>
                <span className="fs-18">ACOUSTIC SOLO</span>
              </div>
              <img src="/img/ACOUSTIC_SOLO.png" width={"50%"} alt="" />
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-sm-6">
          <div className="card gradient-4 card-bx">
            <div className="card-body d-flex align-items-center">
              <div className="me-auto text-white">
                <h2 className="text-white">{count?.rock_song}</h2>
                <span className="fs-18">HARD ROCK</span>
              </div>
              <img src="/img/HARD_ROCK.png" width={"45%"} alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-xl-3 col-xxl-4">
          <div className="row">
            <div className="col-xl-12 col-sm-6">
              <div className="card text-center">
                <div className="card-body">
                  <div id="radialChart" className="radialChart">
                    <RadialDount />
                  </div>
                  <h2>785</h2>
                  <span className="fs-16 text-black">Total Pop Vote</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4">
          <div className="row">
            <div className="col-xl-12 col-sm-6">
              <div className="card text-center">
                <div className="card-body">
                  <div id="radialChart" className="radialChart">
                    <RadialDount />
                  </div>
                  <h2>285</h2>
                  <span className="fs-16 text-black">Total Rock Vote</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-xxl-4">
          <div className="row">
            <div className="col-xl-12 col-sm-6">
              <div className="card text-center">
                <div className="card-body">
                  <div id="radialChart" className="radialChart">
                    <RadialDount />
                  </div>
                  <h2>285</h2>
                  <span className="fs-16 text-black">Total HipHop Vote</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
export default Home;
