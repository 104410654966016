/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";

//import './table.css';
import "./filtering.css";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { axiosInstance } from "../../../../services/AxiosInstance";
export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div>
      Search :{" "}
      <input
        className="ml-2 input-search form-control"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: "20%" }}
      />
    </div>
  );
};

export const Judge = () => {
  const keys = ["id", "fname", "lname", "email", "genre_id"];

  const COLUMNS = [
    ...keys.map((key) => ({
      Header:
        key === "fname"
          ? "First Name"
          : key === "lname"
          ? "Last Name"
          : key === "genre_id"
          ? "Genre"
          : key.replace(/_/g, " "),
      Footer:
        key === "fname"
          ? "First Name"
          : key === "lname"
          ? "Last Name"
          : key === "genre_id"
          ? "Genre"
          : key.replace(/_/g, " "),
      accessor: key === "genre_id" ? "genre.name" : key,
      Filter: ColumnFilter,
    })),

    {
      Header: "Image",
      Footer: "Image",
      accessor: "image",
      Cell: ({ value }) => {
        return (
          <>
            <a href={value} target="_blank" rel="noreferrer">
              <img
                src={value}
                alt="no img Found"
                className="rounded"
                width={40}
                height={40}
              />
            </a>
          </>
        );
      },
    },
    {
      Header: "Action",
      Footer: "Action",
      Cell: ({ row }) => {
        const [EditData, setEditData] = useState(false);
        const [editfname, setEditFname] = useState("");
        const [editlname, setEditLname] = useState("");
        const [editemail, setEditEmail] = useState("");
        const [editPassword, setEditPassword] = useState("");
        const onEditChange = (setState) => (e) => setState(e.target.value);
        const onDelete = (id) => {
          setLoader(true);
          axiosInstance
            .delete(`/admin/judges/${id}`)
            .then(function (response) {
              console.log(response, "response");
              GetJudge();
              toast.success("Record Has been deleted!!");
              setLoader(false);
            })
            .catch(function (error) {
              setLoader(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              Swal.fire({
                showCloseButton: true,
                toast: true,
                icon: "error",
                title: error?.response?.data?.message,
                animation: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
            });
        };
        const onrandompassword = (id) => {
          setLoader(true);
          axiosInstance
            .post(`/admin/judges/update_n_send_password/${id}`)
            .then(function (response) {
              console.log(response, "response");
              if (response.data.status === true) {
                GetJudge();
                toast.success(response.data.response);
                setLoader(false);
              } else {
                toast.success(response.data.response);
                setLoader(false);
              }
            })
            .catch(function (error) {
              setLoader(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              Swal.fire({
                showCloseButton: true,
                toast: true,
                icon: "error",
                title: error?.response?.data?.message,
                animation: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
            });
        };
        const onEdit = (id) => {
          setLoader(true);
          const urlencoded = new URLSearchParams();
          urlencoded.append(
            "email",
            editemail ? editemail : row.original.email
          );
          urlencoded.append("password", editPassword);
          urlencoded.append(
            "fname",
            editfname ? editfname : row.original.fname
          );
          urlencoded.append(
            "lname",
            editlname ? editlname : row.original.lname
          );
          urlencoded.append("genre_id", row.original.genre_id);
          axiosInstance
            .put(`/admin/judges/${id}`, urlencoded)
            .then(function (response) {
              console.log(response, "response");
              if (response.data.status === true) {
                GetJudge();
                toast.success(response.data.response);
                setLoader(false);
              } else {
                toast.error(response.data.error);
                setLoader(false);
              }
            })
            .catch(function (error) {
              setLoader(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              Swal.fire({
                showCloseButton: true,
                toast: true,
                icon: "error",
                title: error?.response?.data?.message,
                animation: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
            });
        };
        return (
          <>
            <div className="d-flex ">
              <button
                title="Update Password"
                onClick={() => onrandompassword(row.original.id)}
                className={`btn btn-outline-success btn-sm rounded`}
              >
                <i className="fa fa-lock" />
              </button>
              <button
                onClick={() => onDelete(row.original.id)}
                title="Delete"
                className={`btn btn-outline-danger btn-sm rounded`}
              >
                <i className="fa fa-trash" />
              </button>
              <button
                onClick={() => setEditData(true)}
                title="Edit"
                className={`btn btn-outline-success btn-sm rounded`}
              >
                <i className="fa fa-edit" />
              </button>
            </div>
            <Modal show={EditData} onHide={() => setEditData(false)} size="md">
              <ModalHeader>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <h4 className="mt-3">Edit Judge</h4>
                    </div>
                    <div className="col-md-6">
                      <div className="float-right"></div>
                    </div>
                  </div>
                </div>
              </ModalHeader>
              <ModalBody>
                <div className="container">
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="">First Name</label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        value={row.original.fname}
                        onChange={onEditChange(setEditFname)}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="">Last Name</label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        value={row.original.lname}
                        onChange={onEditChange(setEditLname)}
                      />
                    </div>

                    <div className="col-md-12">
                      <label htmlFor="">Email Name</label>
                      <input
                        type="email"
                        className="form-control mb-3"
                        value={row.original.email}
                        onChange={onEditChange(setEditEmail)}
                      />
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="">New Password</label>
                      <input
                        type="password"
                        className="form-control mb-3"
                        onChange={onEditChange(setEditPassword)}
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <div
                  className="btn btn-outline-danger"
                  onClick={() => setEditData(false)}
                >
                  Close
                </div>

                <div
                  className="btn btn-outline-success"
                  onClick={() => onEdit(row.original.id)}
                >
                  Sumbit
                </div>
              </ModalFooter>
            </Modal>
          </>
        );
      },
    },
  ];

  const [columns] = useState(COLUMNS);

  const [data, setData] = useState([]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;
  const [loader, setLoader] = useState(false);
  const [showData, setShowData] = useState(false);
  const [genres, setgenres] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState("");
  const [lname, setLname] = useState("");
  const [fname, setFname] = useState("");
  const [image, setImage] = useState([]);
  const onChange = (setState) => (e) => {
    setState(e.target.value);
  };
  const onShowData = () => setShowData(true);
  const onHideData = () => setShowData(false);

  const GetJudge = () => {
    setLoader(true);
    axiosInstance
      .get("admin/judges")
      .then(function (response) {
        console.log(response, "Getting Compaines");
        if (response?.data.status === true) {
          setData(response?.data.data);
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  const Getgenres = () => {
    setLoader(true);
    axiosInstance
      .get("genres")
      .then(function (response) {
        console.log(response, "Getting Compaines");
        if (response?.data.status === true) {
          setgenres(response?.data.data);
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  useEffect(() => {
    GetJudge();
    Getgenres();
  }, []);
  const AddJudges = () => {
    setLoader(true);
    const formdata = new FormData();
    formdata.append("email", email);
    formdata.append("password", password);
    formdata.append("fname", fname);
    formdata.append("lname", lname);
    formdata.append("genre_id", type);
    formdata.append("image", image[0]);
    axiosInstance
      .post("admin/judges", formdata)
      .then(function (response) {
        console.log(response, "Getting Compaines");
        if (response?.data.status === true) {
          setLoader(false);
          GetJudge();
          toast.success(response.data.message);
          setShowData(false);
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
      });
  };
  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="card">
        <div>
          <div className="card-header">
            <h4 className="card-title float-left">Judge</h4>

            <button className="btn btn-primary ms-2" onClick={onShowData}>
              Add Data <i className="fa fa-plus" />
            </button>
          </div>
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table
              {...getTableProps()}
              id="table-to-export" // Add the id attribute
              className="table display"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* {column.canFilter ? column.render("Filter") : null} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
              {""}
            </span>
            <span className="table-index">
              Go to page :{" "}
              <input
                type="number"
                className="ml-2"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </span>
          </div>
          <div className="text-center">
            <div className="filter-pagination  mt-3">
              <button
                className=" previous-button"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>

              <button
                className="previous-button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous
              </button>
              <button
                className="next-button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </button>
              <button
                className=" next-button"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showData} onHide={() => setShowData(false)} size="md">
        <ModalHeader>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <h4 className="mt-3">Add Judges</h4>
              </div>
              <div className="col-md-6">
                <div className="float-right"></div>
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="">First Name</label>
                <input
                  type="text"
                  className="form-control mb-3"
                  onChange={onChange(setFname)}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="">Last Name</label>
                <input
                  type="text"
                  className="form-control mb-3"
                  onChange={onChange(setLname)}
                />
              </div>
              <div className="col-md-12">
                <label htmlFor="">Email Name</label>
                <input
                  type="email"
                  className="form-control mb-3"
                  onChange={onChange(setEmail)}
                />
              </div>
              <div className="col-md-12">
                <label htmlFor="">Password</label>
                <input
                  type="password"
                  className="form-control mb-3"
                  onChange={onChange(setPassword)}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="">Genre</label>
                <select
                  type="file"
                  className="form-control"
                  onChange={onChange(setType)}
                >
                  <option selected hidden>
                    Select Genre....
                  </option>
                  {genres &&
                    genres.map((e) => <option value={e.id}>{e.name}</option>)}
                </select>
              </div>
              <div className="col-md-6">
                <label htmlFor="">Profile Picture</label>
                <input
                  type="file"
                  className="form-control p-3"
                  onChange={(e) => setImage(e.target.files)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="btn btn-outline-danger" onClick={onHideData}>
            Close
          </div>

          <div className="btn btn-outline-success" onClick={AddJudges}>
            Sumbit
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default Judge;
