import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../services/AxiosInstance";
import { Autoplay, EffectCube, Pagination } from "swiper";
import "swiper/css/effect-cube";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
const FinalList = () => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const GetEvents = () => {
    setLoader(true);
    axiosInstance
      .get(`admin/events`)
      .then((response) => {
        if (response.data.status === true) {
          setLoader(false);
          setData(response.data.data.data);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };
  useEffect(() => {
    GetEvents();
  }, []);
  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="container">
        <h3>Final List</h3>
        <div className="row">
          <div class="col-lg-4 col-sm-12 my-3">
            <input
              type="search"
              placeholder="Search..."
              className="form-control"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          {data &&
            (data?.filter((e) =>
              e.title.toLowerCase().includes(searchQuery.toLowerCase())
            ).length === 0 ? (
              <p>No Event Found</p>
            ) : (
              data
                .filter((e) =>
                  e.title.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .map((e) => {
                  return (
                    <>
                      <div className="col-md-4">
                        <Swiper
                          effect={"cube"}
                          grabCursor={true}
                          cubeEffect={{
                            shadow: true,
                            slideShadows: true,
                            shadowOffset: 20,
                            shadowScale: 0.94,
                          }}
                          spaceBetween={30}
                          pagination={{ clickable: true }}
                          modules={[Autoplay, EffectCube, Pagination]}
                          autoplay={true}
                          loop={true}
                          className="mySwiper3"
                        >
                          <div className="card shadow-lg p-3">
                            {e.images &&
                              e.images.map((x, index) => (
                                <>
                                  <SwiperSlide
                                    key={index}
                                    className="card shadow-lg mt-5 mb-5 p-3"
                                  >
                                    <Link
                                      to={`/FinalListDetails/${e.id}`}
                                      // onClick={() => ViewEvent(e.id)}
                                    >
                                      <div className="position-relative">
                                        <img
                                          src={x.image}
                                          className="w-100"
                                          height={300}
                                          alt={""}
                                        />
                                        <div className="image-overlay"></div>
                                      </div>
                                      <h3 className="text-center mt-2 fw-bold text-primary">
                                        {e.title.length > 21
                                          ? e.title.slice(0, 21) + "..."
                                          : e.title}
                                      </h3>
                                    </Link>
                                  </SwiperSlide>
                                  <br />
                                </>
                              ))}
                          </div>
                        </Swiper>
                      </div>
                    </>
                  );
                })
            ))}
        </div>
      </div>
    </>
  );
};

export default FinalList;
