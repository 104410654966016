/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from "react";
//** Import Image */
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../../services/AxiosInstance";
import PageTitle from "../../../layouts/PageTitle";
import { loginConfirmedAction } from "../../../../store/actions/AuthActions";

const AppProfile = () => {
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const [fname, setFname] = useState("");
  const [email, setEmail] = useState("");
  const [lname, setLname] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const onChange = (setState) => (e) => {
    setState(e.target.value);
  };
  const UserDetails = useSelector((state) => state.auth.auth);
  console.log(UserDetails, "auth");
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [fields, setFields] = useState([
    { id: 1, day: "", startTime: "", endTime: "" },
  ]);

  const addField = () => {
    const newField = {
      id: fields.length + 1,
      day: "",
      startTime: "",
      endTime: "",
    };
    setFields([...fields, newField]);
  };

  const removeField = (id) => {
    const updatedFields = fields.filter((field) => field.id !== id);
    setFields(updatedFields);
  };

  const handleChange = (id, field, value) => {
    const updatedFields = fields.map((f) => {
      if (f.id === id) {
        return { ...f, [field]: value };
      }
      return f;
    });
    setFields(updatedFields);
  };

  const isDaySelected = (selectedDay, fieldId) => {
    return fields.some(
      (field) => field.day === selectedDay && field.id !== fieldId
    );
  };
  console.log(fields, "field");
  const ref = useRef();

  const onClick = () => {
    ref.current.click();
  };

  const imgUpload = (image) => {
    setLoader(true);
    const formdata = new FormData();
    formdata?.append("image", image[0]);
    axiosInstance
      .post(`professional/update/profile_pic`, formdata)
      .then((response) => {
        if (response.data?.status === true) {
          toast.success(response.data?.response);
          userData();
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((errors) => {
        setLoader(false);

        console.log(errors, "errors");
      });
  };
  const DeleteImg = () => {
    axiosInstance
      .delete(`professional/delete/profile_pic`)
      .then((response) => {
        if (response.data?.status === true) {
          toast.success(response.data?.response);
          userData();
        }
      })
      .catch((errors) => {
        console.log(errors, "errors");
      });
  };
  const Deletedocxs = (id) => {
    setLoader(true);
    axiosInstance
      .delete(`professional/delete/document/${id}`)
      .then((response) => {
        if (response.data?.status === true) {
          toast.success(response.data?.response);
          userData();
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((errors) => {
        setLoader(false);

        console.log(errors, "errors");
      });
  };

  const userData = () => {
    setLoader(true);
    axiosInstance
      .get(`professional/show/${UserDetails.localId}`)
      .then((response) => {
        console.log(response);
        const Data = response.data?.data;
        if (response.data?.status === true) {
          setData(Data);
          dispatch(loginConfirmedAction(response?.data));
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((errors) => {
        setLoader(false);
        console.log(errors);
      });
  };
  useEffect(() => {
    userData();
  }, []);
  const viewPicture = () => {
    window.open(data?.image, "_blank");
  };
  const [docxs, setDocxs] = useState([{ documentType: "", file: null }]);

  const handleDocumentTypeChange = (index, value) => {
    const updatedDocxs = [...docxs];
    updatedDocxs[index].documentType = value;
    setDocxs(updatedDocxs);
  };

  const handleFileChange = (index, e) => {
    const file = e.target.files[0];
    const updatedDocxs = [...docxs];
    updatedDocxs[index].file = file;
    setDocxs(updatedDocxs);
  };

  const addDocx = () => {
    const updatedDocxs = [...docxs, { documentType: "", file: null }];
    setDocxs(updatedDocxs);
  };

  const removeDocx = (index) => {
    const updatedDocxs = [...docxs];
    updatedDocxs.splice(index, 1);
    setDocxs(updatedDocxs);
  };

  const removeAllDocxs = () => {
    setDocxs([{ documentType: "", file: null }]);
  };

  const AddDocuments = () => {
    setLoader(true);
    const formdata = new FormData();
    docxs.map((e, i) => formdata?.append(`media[${i}][type]`, e.documentType));
    docxs.map((e, i) => formdata?.append(`media[${i}][document]`, e.file));
    axiosInstance
      .post("professional/upload/media", formdata)
      .then((response) => {
        if (response.data?.status === true) {
          toast.success(response.data?.response);
          userData();
          setDocxs([{ documentType: "", file: null }]);
          removeAllDocxs();
          setLoader(false);
        } else {
          toast.error(response.data?.error);
          setLoader(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoader(false);
      });
  };
  const onUpdate = () => {
    setLoader(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("carehome_id", data?.carehome_id);
    urlencoded.append("type", data?.type);
    urlencoded.append("address", fname ? fname : data?.address);
    urlencoded.append("email", email ? email : data?.email);
    urlencoded.append("license_status");
    urlencoded.append("description", lname ? lname : data?.description);
    urlencoded.append("password", password);
    axiosInstance
      .put(`professional/update/${UserDetails.localId}`, urlencoded)
      .then((response) => {
        if (response.data?.status === true) {
          toast.success(response.data?.response);
          userData();
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((errors) => {
        console.log(errors);
        setLoader(false);
      });
  };
  const TimeSlots = () => {
    setLoader(true);
    const formdata = new FormData();
    fields.map((e, i) => formdata?.append(`slot[${i}][day]`, e.day));
    fields.map((e, i) =>
      formdata?.append(`slot[${i}][start_time]`, e.startTime)
    );
    fields.map((e, i) => formdata?.append(`slot[${i}][end_time]`, e.endTime));
    axiosInstance
      .post(`professional/${UserDetails.localId}/slots`, formdata)
      .then((response) => {
        if (response.data?.status === true) {
          toast.success(response.data?.response);
          userData();
          setFields([{ id: 1, day: "", startTime: "", endTime: "" }]);
        } else {
          toast.error(response.data?.error);
          setLoader(false);
        }
      })
      .catch((errors) => {
        setLoader(false);
      });
  };
  const removeSlot = (id) => {
    setLoader(true);
    axiosInstance
      .delete(`professional/slots/${id}`)
      .then((response) => {
        console.log(response, "delete");
        if (response.data === 1) {
          toast.success("Time Slot Deleted");
          userData();
        } else {
          toast.error(response.data?.error);
          setLoader(false);
        }
      })
      .catch((errors) => {
        setLoader(false);
      });
  };
  return (
    <Fragment>
      {loader ? <div className="loader"></div> : null}
      <PageTitle activeMenu="Profile" motherMenu="App" />

      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content ">
                <div className="cover-photo rounded"></div>
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  <Dropdown className="dropdown ms-auto">
                    <Dropdown.Toggle
                      //   variant="primary"
                      className="bg-transparent border-0"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <img
                        src={data?.image}
                        className=" rounded-circle"
                        alt="profile"
                        height={87}
                        width={87}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                      <Dropdown.Item
                        className="dropdown-item"
                        onClick={viewPicture}
                      >
                        <i className="fa fa-user-circle text-primary me-2" />
                        View Profile Picture
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="dropdown-item"
                        onClick={onClick}
                      >
                        <i className="fa fa-plus text-primary me-2" />
                        Add New Profile Picture
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="dropdown-item"
                        onClick={DeleteImg}
                      >
                        <i className="fa fa-trash text-danger me-2" />
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <input
                  type="file"
                  ref={ref}
                  className="d-none"
                  onChange={(e) => {
                    imgUpload(e.target.files);
                  }}
                />
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0 text-capitalize">
                      {data?.fname + data?.lname}
                      <span className="text-muted fs-5">({data?.type})</span>
                    </h4>
                    <p>{data?.carehome?.director}</p>
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">{data?.email}</h4>
                    <p>Email</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <div className="tab-content">
                    <div
                      id="profile-settings"
                      className={`tab-pane fade active show`}
                    >
                      <div className="pt-3">
                        <div className="settings-form">
                          <h4 className="text-primary">Account Setting</h4>
                          <form onSubmit={(e) => e.preventDefault()}>
                            <div className="row">
                              <div className="form-group mb-3 col-md-6">
                                <label className="form-label">Email</label>
                                <input
                                  defaultValue={data?.email}
                                  type="email"
                                  placeholder="Email"
                                  className="form-control"
                                  onChange={onChange(setEmail)}
                                />
                              </div>
                              <div className="form-group mb-3 col-md-6">
                                <label className="form-label">Password</label>
                                <input
                                  type="password"
                                  placeholder="Password"
                                  className="form-control"
                                  onChange={onChange(setPassword)}
                                />
                              </div>
                              <div className="form-group mb-3 col-md-6">
                                <label className="form-label">First Name</label>
                                <input
                                  defaultValue={data?.fname}
                                  type="text"
                                  placeholder="1234 Main St"
                                  className="form-control"
                                  onChange={onChange(setFname)}
                                />
                              </div>
                              <div className="form-group mb-3 col-md-6">
                                <label className="form-label">Last Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={data?.lname}
                                  onChange={onChange(setLname)}
                                />
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                <label className="form-label">
                                  Availability
                                </label>

                                {fields.map((field, i) => (
                                  <div className="row" key={field.id}>
                                    <div className="form-group mb-3 col-md-3">
                                      <label className="form-label">Days</label>
                                      <select
                                        value={field.day}
                                        className="form-control"
                                        onChange={(e) =>
                                          handleChange(
                                            field.id,
                                            "day",
                                            e.target.value
                                          )
                                        }
                                        placeholder="Day"
                                        disabled={isDaySelected(
                                          field.day,
                                          field.id
                                        )}
                                      >
                                        <option value="">Select a day</option>
                                        {daysOfWeek.map((day) => (
                                          <option
                                            key={day}
                                            value={day}
                                            disabled={isDaySelected(
                                              day,
                                              field.id
                                            )}
                                          >
                                            {day}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                      <label className="form-label">
                                        Start Time
                                      </label>
                                      <input
                                        className="form-control"
                                        type="time"
                                        value={field.startTime}
                                        onChange={(e) =>
                                          handleChange(
                                            field.id,
                                            "startTime",
                                            e.target.value
                                          )
                                        }
                                        placeholder="Start Time"
                                      />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                      <label className="form-label">
                                        End Time
                                      </label>
                                      <input
                                        className="form-control"
                                        type="time"
                                        value={field.endTime}
                                        onChange={(e) =>
                                          handleChange(
                                            field.id,
                                            "endTime",
                                            e.target.value
                                          )
                                        }
                                        placeholder="End Time"
                                      />
                                    </div>
                                    {i === 0 ? (
                                      <div className="col-md-1">
                                        <button
                                          onClick={addField}
                                          className="btn btn-outline-primary mt-4"
                                        >
                                          <i className="fa fa-plus" />{" "}
                                        </button>
                                      </div>
                                    ) : (
                                      <div className="col-md-1">
                                        <button
                                          className="btn btn-outline-danger mt-4"
                                          onClick={() => removeField(field.id)}
                                        >
                                          <i className="fa fa-trash" />{" "}
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                ))}
                                {data?.slots?.length !== 0
                                  ? data?.slots?.map((field, i) => {
                                      const time = field.start_time;
                                      const endtime = field.end_time;

                                      const convertTo12HourFormat = () => {
                                        const timeParts = time.split(":");
                                        let hour = parseInt(timeParts[0], 10);
                                        const minute = timeParts[1];

                                        let period = "AM";

                                        if (hour === 0) {
                                          hour = 12;
                                        } else if (hour >= 12) {
                                          period = "PM";
                                          if (hour > 12) {
                                            hour -= 12;
                                          }
                                        }

                                        return `${hour}:${minute} ${period}`;
                                      };
                                      const endconvertTo12HourFormat = () => {
                                        const timeParts = endtime.split(":");
                                        let hour = parseInt(timeParts[0], 10);
                                        const minute = timeParts[1];

                                        let period = "AM";

                                        if (hour === 0) {
                                          hour = 12;
                                        } else if (hour >= 12) {
                                          period = "PM";
                                          if (hour > 12) {
                                            hour -= 12;
                                          }
                                        }

                                        return `${hour}:${minute} ${period}`;
                                      };

                                      const convertedTime =
                                        convertTo12HourFormat(time);
                                      const endconvertedTime =
                                        endconvertTo12HourFormat(endtime);

                                      return (
                                        <>
                                          <div className="row" key={field.id}>
                                            <div className="form-group mb-3 col-md-3">
                                              <label className="form-label">
                                                Days
                                              </label>
                                              <input
                                                value={field.day}
                                                className="form-control"
                                              />
                                            </div>
                                            <div className="form-group mb-3 col-md-4">
                                              <label className="form-label">
                                                Start Time
                                              </label>
                                              <input
                                                className="form-control"
                                                disabled
                                                value={convertedTime}
                                                placeholder="Start Time"
                                              />
                                            </div>
                                            <div className="form-group mb-3 col-md-4">
                                              <label className="form-label">
                                                End Time
                                              </label>
                                              <input
                                                className="form-control"
                                                disabled
                                                value={endconvertedTime}
                                                placeholder="End Time"
                                              />
                                            </div>

                                            <div className="col-md-1">
                                              <button
                                                className="btn btn-outline-danger mt-4"
                                                onClick={() =>
                                                  removeSlot(field.id)
                                                }
                                              >
                                                <i className="fa fa-trash" />{" "}
                                              </button>
                                            </div>
                                          </div>
                                          ;
                                        </>
                                      );
                                    })
                                  : null}
                                <div className="float-end">
                                  <div
                                    className="btn btn-primary float-end mb-3"
                                    onClick={TimeSlots}
                                  >
                                    Add All Time Slots
                                  </div>
                                </div>
                              </div>
                            </div>
                            <h4 className="text-primary">Care Home Detail </h4>
                            <div className="row">
                              <div className="form-group mb-3 col-md-4">
                                <label className="form-label">Director</label>
                                <input
                                  readOnly
                                  type="text"
                                  className="form-control"
                                  defaultValue={data?.carehome?.director}
                                />
                              </div>
                              <div className="form-group mb-3 col-md-4">
                                <label className="form-label">License</label>
                                <input
                                  readOnly
                                  type="text"
                                  className="form-control"
                                  defaultValue={data?.carehome?.license}
                                />
                              </div>
                              <div className="form-group mb-3 col-md-4">
                                <label className="form-label">
                                  License Status
                                </label>
                                <input
                                  readOnly
                                  type="text"
                                  className="form-control"
                                  defaultValue={data?.carehome?.license_status}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="form-group mb-3 col-md-4">
                                <label className="form-label">Town</label>
                                <input
                                  readOnly
                                  type="text"
                                  className="form-control"
                                  defaultValue={data?.carehome?.town}
                                />
                              </div>

                              <div className="form-group mb-3 col-md-4">
                                <label className="form-label">Telephone</label>
                                <input
                                  readOnly
                                  type="tel"
                                  className="form-control"
                                  defaultValue={data?.carehome?.telephone}
                                />
                              </div>
                              <div className="form-group mb-3 col-md-4">
                                <label className="form-label">Ability</label>
                                <input
                                  readOnly
                                  type="text"
                                  className="form-control"
                                  defaultValue={data?.carehome?.ability}
                                />
                              </div>
                              <div className="form-group mb-3 col-md-4">
                                <label className="form-label">Price</label>
                                <input
                                  readOnly
                                  type="number"
                                  className="form-control"
                                  defaultValue={data?.carehome?.pricing}
                                />
                              </div>

                              <div className="form-group mb-3 col-md-12">
                                <label className="form-label">
                                  Description
                                </label>
                                <textarea
                                  defaultValue={data?.carehome?.description}
                                  rows={10}
                                  readOnly
                                  type="file"
                                  className="form-control mb-3 p-3"
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div
                                className={
                                  data?.professional_media?.length !== 0
                                    ? "col-md-5"
                                    : "col-md-12"
                                }
                              >
                                <h3
                                  className="form-check-label text-center my-3"
                                  htmlFor="gridCheck"
                                >
                                  Upload Documents
                                </h3>
                                {docxs.map((docx, index) => {
                                  return (
                                    <div className="row" key={index}>
                                      <div className="form-group mb-3 col-md-5 col-sm-5">
                                        <label className="form-label">
                                          Select Document Type
                                        </label>
                                        <select
                                          onChange={(e) => {
                                            handleDocumentTypeChange(
                                              index,
                                              e.target.value
                                            );
                                          }}
                                          className="form-control"
                                        >
                                          <option selected disabled hidden>
                                            Select Document Type....
                                          </option>
                                          <option value="driver_license">
                                            Driver license
                                          </option>
                                          <option value="ley_300">
                                            Ley 300
                                          </option>
                                          <option value="covid_19_vaccine">
                                            Covid 19 vaccine
                                          </option>
                                          <option value="buena_conduct_certificate">
                                            Buena conduct certificate
                                          </option>
                                          <option value="rn_license_certificate">
                                            Rn license certificate
                                          </option>
                                        </select>
                                      </div>
                                      <div className="form-group mb-3 col-md-6 col-sm-6">
                                        <label className="form-label">
                                          Upload Document
                                        </label>
                                        <input
                                          type="file"
                                          className="form-control "
                                          onChange={(e) =>
                                            handleFileChange(index, e)
                                          }
                                        />
                                      </div>
                                      {index === 0 ? (
                                        <div className="col-md-1 col-sm-1">
                                          <button
                                            onClick={addDocx}
                                            className="btn btn-outline-primary mt-4"
                                          >
                                            <i className="fa fa-plus" />{" "}
                                          </button>
                                        </div>
                                      ) : (
                                        <div className="col-md-1 col-sm-1">
                                          <button
                                            className="btn btn-outline-danger mt-4"
                                            onClick={() => removeDocx(index)}
                                          >
                                            <i className="fa fa-trash" />{" "}
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                                <div className="float-end">
                                  <button
                                    className="btn btn-primary"
                                    onClick={AddDocuments}
                                  >
                                    Add Documents
                                  </button>
                                </div>
                              </div>
                              {data?.professional_media?.length !== 0 ? (
                                <>
                                  <div className="col-md-1" />
                                  <div className="col-md-6">
                                    <h3
                                      className="form-check-label text-center my-3"
                                      htmlFor="gridCheck"
                                    >
                                      Uploaded Documents
                                    </h3>
                                    <div className="row">
                                      {data?.professional_media?.map((e) => {
                                        return (
                                          <>
                                            <div
                                              className="col-md-3"
                                              style={{ position: "relative" }}
                                            >
                                              <label
                                                htmlFor=""
                                                className="form-check-label text-center my-3 text-capitalize"
                                              >
                                                {e.type.replace(/_/g, " ")}
                                              </label>
                                              <br />
                                              <a
                                                href={e.document}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <img
                                                  src={e.document}
                                                  alt=""
                                                  className="img-thumbnail w-100 text-center"
                                                  height={50}
                                                />
                                              </a>
                                              <i
                                                className="fa fa-times btn-danger fs-6 rounded-circle p-1"
                                                onClick={() =>
                                                  Deletedocxs(e.id)
                                                }
                                                style={{
                                                  position: "absolute",
                                                  top: "12px",
                                                  right: "0px",
                                                }}
                                              />
                                            </div>
                                          </>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="text-center">
                                  <button
                                    className="btn btn-primary w-50 mt-5"
                                    onClick={onUpdate}
                                  >
                                    Update
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AppProfile;
