import React, { useEffect, useRef } from "react";
import PlayerControl from "./PlayerControl";
import PlayerDetail from "./PlayerDetail";
import SongProgressBar from "./SongProgressBar";

function Player(songs) {
  const audioEl = useRef(null);
  const { isPlaying, updatePlayingIndex, PlayingId } = songs;
  console.log(songs, "<========= songs");
  useEffect(() => {
    if (isPlaying) {
      audioEl.current.play();
    } else {
      audioEl.current.pause();
    }
  }, [isPlaying]);
  console.log(
    isPlaying,
    updatePlayingIndex,
    PlayingId,
    "isPlaying, updatePlayingIndex, PlayingId"
  );
  return (
    <div
      className="c-player"
     
    >
      <audio src={songs.audio} ref={audioEl} />
      <h4>Playing Now</h4>
      <PlayerDetail song={songs} />
      <PlayerControl
        isPlaying={isPlaying}
        PlayingId={PlayingId}
        setIsPlaying={updatePlayingIndex}
      />
      <SongProgressBar audioEl={audioEl} />
      <p>
        <strong style={{ color: "purple" }}>Event: </strong>
        {songs.event}
      </p>
    </div>
  );
}

export default Player;
