/* eslint-disable react-hooks/exhaustive-deps */
import { RangeDatePicker } from "@y0c/react-datepicker";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import Dropzone from "react-dropzone-uploader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Input, Label } from "reactstrap";
import { Autoplay, EffectCube, Pagination } from "swiper";
import "swiper/css/effect-cube";
import { Swiper, SwiperSlide } from "swiper/react";
import { axiosInstance } from "../../services/AxiosInstance";

const Event = () => {
  const navigate = useNavigate();
  const { Eid } = useParams();
  const addquery = document.querySelector(".dzu-inputLabelWithFiles");
  if (addquery) {
    addquery.classList.add("btn", "btn-outline-primary");
  } else {
  }
  const singerData = () => {
    const initialData = [];
    for (let rank = 1; rank <= 9; rank++) {
      initialData.push({ rank, percent: "" });
    }
    return initialData;
  };
  const finalsingerData = () => {
    const initialData = [];
    for (let rank = 1; rank <= 9; rank++) {
      initialData.push({ rank, percent: "" });
    }
    return initialData;
  };
  const singerCap = () => {
    const initialData = [];
    for (let rank = 1; rank <= 4; rank++) {
      initialData.push({ rank, percent: "" });
    }
    return initialData;
  };
  const FinalsingerCap = () => {
    const initialData = [];
    for (let rank = 1; rank <= 4; rank++) {
      initialData.push({ rank, percent: "" });
    }
    return initialData;
  };

  const [basicModal, setBasicModal] = useState(false);
  const [assign, setAssgin] = useState(false);
  const [password, setPassword] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [getEvent, setgetEvent] = useState([]);
  const [judges, setJudges] = useState([]);
  const [viewEvent, setviewEvent] = useState({});
  const [title, setTitle] = useState("");
  const [judge_pool_percent, setjudge_pool_percent] = useState("");
  const [song_upload_fees, setSong_upload_fees] = useState("");
  const [singer_cap_amount, setSinger_cap_amount] = useState(singerCap());
  const [judge_cap_amount, setJudge_cap_amount] = useState("");
  const [
    final_singer_voting_pool_percent,
    setfinal_singer_voting_pool_percent,
  ] = useState("");
  const [singer_voting_pool_percent, setsinger_voting_pool_percent] =
    useState("");
  const [singer_winner_percent, setSinger_winner_percent] = useState(
    singerData()
  );
  const [final_singer_cap_amount, setFinal_Singer_cap_amount] = useState(
    FinalsingerCap()
  );
  const [final_singer_winner_percent, setFianl_Singer_winner_percent] =
    useState(finalsingerData());
  const [singer_upload_percent, setSinger_upload_percent] = useState("");
  const [eventStartDate, setEventStartDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);
  const [singerStartDate, setSingerStartDate] = useState(null);
  const [singerEndDate, setSingerEndDate] = useState(null);
  const [JudgeStartDate, setJudgeStartDate] = useState(null);
  const [JudgeEndDate, setJudgeEndDate] = useState(null);
  const [VoterStartDate, setVoterStartDate] = useState(null);
  const [VoterEndDate, setVoterEndDate] = useState(null);
  const [Final9StartDate, setFinal9StartDate] = useState(null);
  const [Final9EndDate, setFinal9EndDate] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [selectedJudges, setSelectedJudges] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [, setAllJudges] = useState([...judges]);
  const handleEventDate = (startDate, endDate) => {
    setEventStartDate(startDate);
    setEventEndDate(endDate);
  };
  const handleSingerDate = (startDate, endDate) => {
    setSingerStartDate(startDate);
    setSingerEndDate(endDate);
  };
  const handleJudgeDate = (startDate, endDate) => {
    setJudgeStartDate(startDate);
    setJudgeEndDate(endDate);
  };
  const handleVoterDate = (startDate, endDate) => {
    setVoterStartDate(startDate);
    setVoterEndDate(endDate);
  };
  const handleFinal9Date = (startDate, endDate) => {
    setFinal9StartDate(startDate);
    setFinal9EndDate(endDate);
  };
  const handleWinnerChange = (index, field, value) => {
    const updatedData = [...singer_winner_percent];
    updatedData[index][field] = value;
    setSinger_winner_percent(updatedData);
  };
  const handlefinalWinnerChange = (index, field, value) => {
    const updatedData = [...final_singer_winner_percent];
    updatedData[index][field] = value;
    setFianl_Singer_winner_percent(updatedData);
  };
  const handleSingerCap = (index, field, value) => {
    const updatedData = [...singer_cap_amount];
    updatedData[index][field] = value;
    setSinger_cap_amount(updatedData);
  };
  const handlefinalSingerCap = (index, field, value) => {
    const updatedData = [...final_singer_cap_amount];
    updatedData[index][field] = value;
    setFinal_Singer_cap_amount(updatedData);
  };
  const handleArtistChange = (e) => {
    const { value } = e.target;

    setSinger_upload_percent(value);
  };
  const handleSelectJudge = (judge) => {
    setSelectedJudges((prevSelectedJudges) => [...prevSelectedJudges, judge]);
    setAllJudges((prevAllJudges) =>
      prevAllJudges.filter((j) => j.id !== judge.id)
    );
  };
  const handleRemoveJudge = (judge) => {
    setSelectedJudges((prevSelectedJudges) =>
      prevSelectedJudges.filter((j) => j.id !== judge.id)
    );
    setAllJudges((prevAllJudges) => [...prevAllJudges, judge]);
  };
  const ViewEvent = (id) => {
    setLoader(true);
    try {
      axiosInstance
        .get(`admin/events/${id}`)
        .then((response) => {
          if (response.data.status === true) {
            if (response.data.data !== null) {
              setviewEvent(response.data.data);
              setSelectedJudges(response?.data?.data?.judges);
              setEditModal(true);
              Judges();
              setLoader(false);
            } else {
              navigate("/Event");
            }
          } else {
            setLoader(false);
          }
        })
        .catch((errors) => {
          setLoader(false);
        });
    } catch (error) {
      toast.error(error);
    }
  };
  const Event = () => {
    setLoader(true);
    try {
      axiosInstance
        .get(`admin/events`)

        .then((response) => {
          console.log(response);
          if (response.data.status === true) {
            setgetEvent(response?.data?.data?.data);
            console.log(response);
            setLoader(false);
          } else {
            setLoader(false);
          }
        })
        .catch((errors) => {
          setLoader(false);
        });
    } catch (error) {
      toast.error(error);
    }
  };
  const AddEvent = () => {
    if (uploadedImages.length !== 0) {
      setLoader(true);

      const formdata = new FormData();
      formdata.append("title", title);
      formdata.append(
        "event_start_date",
        eventStartDate
          ? eventStartDate.toISOString().slice(0, 10)
          : eventStartDate
      );
      formdata.append(
        "event_end_date",
        eventEndDate ? eventEndDate.toISOString().slice(0, 10) : eventEndDate
      );
      formdata.append(
        "singer_start_date",
        singerStartDate
          ? singerStartDate.toISOString().slice(0, 10)
          : singerStartDate
      );
      formdata.append(
        "singer_end_date",
        singerEndDate ? singerEndDate.toISOString().slice(0, 10) : singerEndDate
      );
      formdata.append(
        "judge_start_date",
        JudgeStartDate
          ? JudgeStartDate.toISOString().slice(0, 10)
          : JudgeStartDate
      );
      formdata.append(
        "judge_end_date",
        JudgeEndDate ? JudgeEndDate.toISOString().slice(0, 10) : JudgeEndDate
      );
      formdata.append(
        "voter_start_date",
        VoterStartDate
          ? VoterStartDate.toISOString().slice(0, 10)
          : VoterStartDate
      );
      formdata.append(
        "voter_end_date",
        VoterEndDate ? VoterEndDate.toISOString().slice(0, 10) : VoterEndDate
      );
      formdata.append(
        "final_voter_start_date",
        Final9StartDate
          ? Final9StartDate.toISOString().slice(0, 10)
          : Final9StartDate
      );
      formdata.append(
        "final_voter_end_date",
        Final9EndDate ? Final9EndDate.toISOString().slice(0, 10) : Final9EndDate
      );
      uploadedImages
        ?.flat(1)
        ?.map((e, i) => formdata.append(`images[${i}]`, e.file));
      formdata.append("song_upload_fees", song_upload_fees);
      formdata.append("singer_cap_amount", JSON.stringify(singer_cap_amount));
      formdata.append(
        "final_singer_cap_amount",
        JSON.stringify(final_singer_cap_amount)
      );
      formdata.append(
        "final_singer_winner_percent",
        JSON.stringify(final_singer_winner_percent)
      );
      formdata.append("judge_cap_amount", judge_cap_amount);
      formdata.append("judge_pool_percent", judge_pool_percent);
      formdata.append(
        "final_singer_voting_pool_percent",
        final_singer_voting_pool_percent
      );
      formdata.append(
        "singer_winner_percent",
        JSON.stringify(singer_winner_percent)
      );

      formdata.append("singer_upload_percent", singer_upload_percent);
      formdata.append("singer_voting_pool_percent", singer_voting_pool_percent);
      axiosInstance
        .post("admin/events", formdata)
        .then((response) => {
          if (response.data.status === true) {
            Event();
            toast.success(response.data.response);
            toast.info("Please Assign Judges On Event");
            setUploadedImages([]);
            ViewEvent(response?.data?.data.id);
            navigate(`/Event/${response?.data?.data.id}`);
            setAssgin(true);
            setEventStartDate(null);
            setsinger_voting_pool_percent("");
            setEventEndDate(null);
            setSingerStartDate(null);
            setSingerEndDate(null);
            setJudgeStartDate(null);
            setJudgeEndDate(null);
            setVoterStartDate(null);
            setVoterEndDate(null);
            setFinal9StartDate(null);
            setFinal9EndDate(null);
            setUploadedImages([]);
            setSelectedJudges([]);
            setSearchQuery("");
            setgetEvent([]);
            setJudges([]);
            setviewEvent({});
            setTitle("");
            setSong_upload_fees("");
            setSinger_cap_amount(singerCap());
            setJudge_cap_amount("");
            setSinger_winner_percent(singerData());
            setFinal_Singer_cap_amount(FinalsingerCap());
            setFianl_Singer_winner_percent(finalsingerData());
            setSinger_upload_percent("");
            setBasicModal(false);
            setjudge_pool_percent("");
            setfinal_singer_voting_pool_percent("");
            setLoader(false);
          } else {
            toast.error(response.data.error);
            setLoader(false);
          }
        })
        .catch((errors) => {
          console.log(errors, "errors");
          setLoader(false);
        });
    } else {
      toast.error("Upload Atleast One Banner Image");
    }
  };
  const DeleteEvent = () => {
    const Deletedata = new URLSearchParams();
    Deletedata.append("password", password);
    setLoader(true);
    try {
      axiosInstance
        .delete(`admin/events/${Eid}`, { data: Deletedata })
        .then((response) => {
          if (response.data === 1) {
            Event();
            toast.success("Deleted");
            console.log(response);
            setEditModal(false);
            setDeleteModal(false);
            navigate("/Event");
            setLoader(false);
          } else {
            toast.error("SomeThing went Wrong");
            setLoader(false);
          }
        })
        .catch((errors) => {
          toast.error("SomeThing went Wrong");
          setLoader(false);
        });
    } catch (error) {
      toast.error(error);
    }
  };
  const DeleteEventImg = (id) => {
    setLoader(true);
    try {
      axiosInstance
        .delete(`admin/events/delete_image/${id}`)
        .then((response) => {
          if (response.data.status === true) {
            ViewEvent(Eid);
            toast.success("Deleted");
            console.log(response);
            setLoader(false);
          } else {
            toast.error("SomeThing went Wrong");
            setLoader(false);
          }
        })
        .catch((errors) => {
          toast.error("SomeThing went Wrong");
          setLoader(false);
        });
    } catch (error) {
      toast.error(error);
    }
  };
  const UpdateEventImg = (id, file) => {
    setLoader(true);
    const formdata = new FormData();
    file.map((e, i) => formdata.append(`images[${i}]`, e.file));

    try {
      axiosInstance
        .post(`admin/events/update_images/${id}`, formdata)
        .then((response) => {
          if (response.data.status === true) {
            ViewEvent(Eid);
            toast.success(response.data.response);
            setUploadedImages("");
            setEditModal(false);
            setLoader(false);
          } else {
            toast.error("SomeThing went Wrong");
            setLoader(false);
          }
        })
        .catch((errors) => {
          toast.error("SomeThing went Wrong");
          setLoader(false);
        });
    } catch (error) {
      toast.error(error);
      setLoader(false);
    }
  };
  const Judges = () => {
    setLoader(true);
    try {
      axiosInstance
        .get(`admin/judges`)
        .then((response) => {
          if (response.data.status === true) {
            setJudges(response.data.data);
            console.log(response);
            setLoader(false);
          } else {
            setLoader(false);
          }
        })
        .catch((errors) => {
          setLoader(false);
        });
    } catch (error) {
      toast.error(error);
    }
  };
  const De_AttachJudges = () => {
    setLoader(true);
    const formdata = new FormData();
    selectedJudges.map((e, i) =>
      formdata.append(`judges[${i}][number]`, i + 1)
    );
    selectedJudges.map((e, i) => formdata.append(`judges[${i}][id]`, e.id));
    try {
      axiosInstance
        .post(`admin/events/${Eid}/attach-judges`, formdata)
        .then((response) => {
          if (response.data.status === true) {
            ViewEvent(Eid);
            setEditModal(false);
            setAssgin(false);
            console.log(response);
            toast.success(response.data.message);
            setLoader(false);
          } else {
            setLoader(false);
          }
        })
        .catch((errors) => {
          setLoader(false);
        });
    } catch (error) {
      toast.error(error);
    }
  };
  const editEvent = (id) => {
    setLoader(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("title", title ? title : viewEvent?.title);
    urlencoded.append("singer_cap_amount", viewEvent?.singer_cap_amount);
    urlencoded.append("song_upload_fees", song_upload_fees);
    urlencoded.append(
      "judge_pool_percent",
      judge_pool_percent ? judge_pool_percent : viewEvent?.judge_pool_percent
    );
    urlencoded.append(
      "final_singer_voting_pool_percent",
      final_singer_voting_pool_percent
        ? final_singer_voting_pool_percent
        : viewEvent?.final_singer_voting_pool_percent
    );
    urlencoded.append(
      "final_singer_cap_amount",
      viewEvent?.final_singer_cap_amount
    );
    urlencoded.append(
      "final_singer_winner_percent",
      viewEvent?.final_singer_winner_percent
    );
    urlencoded.append(
      "singer_voting_pool_percent",
      singer_voting_pool_percent
        ? singer_voting_pool_percent
        : viewEvent?.singer_voting_pool_percent
    );
    urlencoded.append(
      "judge_cap_amount",
      judge_cap_amount ? judge_cap_amount : viewEvent?.judge_cap_amount
    );
    urlencoded.append(
      "singer_winner_percent",
      viewEvent?.singer_winner_percent
    );

    urlencoded.append(
      "singer_upload_percent",
      singer_upload_percent
        ? singer_upload_percent
        : viewEvent?.singer_upload_percent
    );
    urlencoded.append(
      "event_start_date",
      eventStartDate
        ? eventStartDate.toISOString().slice(0, 10)
        : viewEvent?.event_start_date
    );
    urlencoded.append(
      "event_end_date",
      eventEndDate
        ? eventEndDate.toISOString().slice(0, 10)
        : viewEvent?.event_end_date
    );
    urlencoded.append(
      "singer_start_date",
      singerStartDate
        ? singerStartDate.toISOString().slice(0, 10)
        : viewEvent?.singer_start_date
    );
    urlencoded.append(
      "singer_end_date",
      singerEndDate
        ? singerEndDate.toISOString().slice(0, 10)
        : viewEvent?.singer_end_date
    );
    urlencoded.append(
      "judge_start_date",
      JudgeStartDate
        ? JudgeStartDate.toISOString().slice(0, 10)
        : viewEvent?.judge_start_date
    );
    urlencoded.append(
      "judge_end_date",
      JudgeEndDate
        ? JudgeEndDate.toISOString().slice(0, 10)
        : viewEvent?.judge_end_date
    );
    urlencoded.append(
      "voter_start_date",
      VoterStartDate
        ? VoterStartDate.toISOString().slice(0, 10)
        : viewEvent?.voter_start_date
    );
    urlencoded.append(
      "voter_end_date",
      VoterEndDate
        ? VoterEndDate.toISOString().slice(0, 10)
        : viewEvent?.voter_end_date
    );
    urlencoded.append(
      "final_voter_start_date",
      Final9StartDate
        ? Final9StartDate.toISOString().slice(0, 10)
        : viewEvent?.final_voter_start_date
    );
    urlencoded.append(
      "final_voter_end_date",
      Final9EndDate
        ? Final9EndDate.toISOString().slice(0, 10)
        : viewEvent?.final_voter_end_date
    );
    try {
      axiosInstance
        .put(`admin/events/${id}`, urlencoded)
        .then((response) => {
          if (response.data.status === true) {
            setviewEvent(response.data.data);
            setSelectedJudges(response?.data?.data?.judges);
            setEditModal(true);
            Judges();
            Event();
            setLoader(false);
            setEventStartDate(null);
            setEventEndDate(null);
            setSingerStartDate(null);
            setSingerEndDate(null);
            setJudgeStartDate(null);
            setJudgeEndDate(null);
            setVoterStartDate(null);
            setVoterEndDate(null);
            setFinal9StartDate(null);
            setFinal9EndDate(null);
            setUploadedImages([]);
            setSelectedJudges([]);
            setSearchQuery("");
            setBasicModal(false);
            setAssgin(false);
            setEditModal(false);
            setgetEvent([]);
            setJudges([]);
            setviewEvent({});
            setTitle("");
            setSong_upload_fees("");
            setSinger_cap_amount(singerCap());
            setJudge_cap_amount("");
            setSinger_winner_percent(singerData());
            setFinal_Singer_cap_amount(FinalsingerCap());
            setFianl_Singer_winner_percent(finalsingerData());
            setSinger_upload_percent("");
            setjudge_pool_percent("");
            setfinal_singer_voting_pool_percent("");
          } else {
            setLoader(false);
          }
        })
        .catch((errors) => {
          setLoader(false);
        });
    } catch (error) {
      toast.error(error);
    }
  };
  useEffect(() => {
    Event();
  }, []);
  const handleChangeStatus = ({ meta, remove }, status, file) => {
    if (status === "done") {
      // Check if the file is already in the uploadedImages array

      setUploadedImages((prevImages) => [...prevImages, file]);

      if (editModal) {
        UpdateEventImg(viewEvent?.id, file);
      }
    }
    if (status === "removed") {
      toast.success("removed");
      setUploadedImages((prevImages) =>
        prevImages.filter((image) => image.id !== meta.id)
      );
    }
  };
  useEffect(() => {
    if (Eid) {
      ViewEvent(Eid);
    }
  }, []);
  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="row mb-3">
              <div className="col-md-8">
                <h3>Events</h3>
              </div>
              <div className="col-md-4">
                <button
                  className="btn float-end btn-outline-primary"
                  onClick={() => {
                    setBasicModal(true);
                    setEditModal(false); // Close the editModal when basicModal is set to true
                  }}
                >
                  Add New Event
                </button>
              </div>
            </div>
            <div className="row">
              <div class="col-lg-4 col-sm-12 my-3">
                <input
                  type="search"
                  placeholder="Search..."
                  className="form-control"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-3">
                <div>
                  {getEvent &&
                    (getEvent?.filter((e) =>
                      e.title.toLowerCase().includes(searchQuery.toLowerCase())
                    ).length === 0 ? (
                      <p>No Event Found</p>
                    ) : (
                      getEvent
                        .filter((e) =>
                          e.title
                            .toLowerCase()
                            .includes(searchQuery.toLowerCase())
                        )
                        .map((e) => {
                          return (
                            <>
                              {" "}
                              <Swiper
                                effect={"cube"}
                                grabCursor={true}
                                cubeEffect={{
                                  shadow: true,
                                  slideShadows: true,
                                  shadowOffset: 20,
                                  shadowScale: 0.94,
                                }}
                                spaceBetween={30}
                                pagination={{ clickable: true }}
                                modules={[Autoplay, EffectCube, Pagination]}
                                autoplay={true}
                                loop={true}
                                className="mySwiper3"
                              >
                                {" "}
                                <div className="card shadow-lg p-3">
                                  {" "}
                                  {e.images &&
                                    e.images.map((x, index) => (
                                      <>
                                        <SwiperSlide
                                          key={index}
                                          className="card shadow-lg mt-5 mb-5 p-3"
                                        >
                                          {" "}
                                          <Link
                                            to={`/Event/${e.id}`}
                                            onClick={() => ViewEvent(e.id)}
                                          >
                                            {" "}
                                            <div className="position-relative">
                                              {" "}
                                              <img
                                                src={x.image}
                                                className="w-100"
                                                height={300}
                                                alt=""
                                              />{" "}
                                              <div className="image-overlay"></div>{" "}
                                              <div
                                                className="position-absolute text-center  w-100 z-1"
                                                style={{ top: "3%" }}
                                              >
                                                {" "}
                                                <h1 className="text-center text-white my-custom-font">
                                                  {" "}
                                                  {e.title}{" "}
                                                </h1>{" "}
                                              </div>{" "}
                                            </div>{" "}
                                          </Link>{" "}
                                        </SwiperSlide>
                                        <br />
                                      </>
                                    ))}{" "}
                                </div>{" "}
                              </Swiper>{" "}
                            </>
                          );
                        })
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Add Modal */}
      <Modal className="fade" size="xl" show={basicModal}>
        <Modal.Header>
          <Modal.Title>Add New Event</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setBasicModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Col md={12}>
              <Row>
                <Col md={12} className="mb-3">
                  <Label>Event Title</Label>
                  <Input
                    type="text"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <Label>Event Start-End Date</Label>
                  <RangeDatePicker
                    startText="Start"
                    endText="End"
                    startPlaceholder="Start Date"
                    endPlaceholder="End Date"
                    onChange={handleEventDate}
                    dateFormat="YYYY-MM-DD" // Format to display the date
                    selectedStartDate={eventStartDate} // Pass the selected start date
                    selectedEndDate={eventEndDate} // Pass the selected end date
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <Label>Artist Start-End Date</Label>
                  <RangeDatePicker
                    startText="Start"
                    endText="End"
                    startPlaceholder="Start Date"
                    endPlaceholder="End Date"
                    onChange={handleSingerDate}
                    // Set the following props to make the picker only include date
                    showTimeSelect={false} // This disables the time picker
                    dateFormat="YYYY-MM-DD" // Format to display the date
                    selectedStartDate={singerStartDate} // Pass the selected start date
                    selectedEndDate={singerEndDate} // Pass the selected end date
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <Label>Judge Start-End Date</Label>
                  <RangeDatePicker
                    startText="Start"
                    endText="End"
                    startPlaceholder="Start Date"
                    endPlaceholder="End Date"
                    onChange={handleJudgeDate}
                    // Set the following props to make the picker only include date
                    showTimeSelect={false} // This disables the time picker
                    dateFormat="YYYY-MM-DD" // Format to display the date
                    selectedStartDate={JudgeStartDate} // Pass the selected start date
                    selectedEndDate={JudgeEndDate} // Pass the selected end date
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <Label>Voting Start-End Date</Label>
                  <RangeDatePicker
                    startText="Start"
                    endText="End"
                    startPlaceholder="Start Date"
                    endPlaceholder="End Date"
                    onChange={handleVoterDate}
                    showTimeSelect={false}
                    dateFormat="YYYY-MM-DD" // Use lowercase format tokens
                    selectedStartDate={VoterStartDate}
                    selectedEndDate={VoterEndDate}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <Label>Final 9 Start-End Date</Label>
                  <RangeDatePicker
                    startText="Start"
                    endText="End"
                    startPlaceholder="Start Date"
                    endPlaceholder="End Date"
                    onChange={handleFinal9Date}
                    showTimeSelect={false}
                    dateFormat="YYYY-MM-DD" // Use lowercase format tokens
                    selectedStartDate={VoterStartDate}
                    selectedEndDate={VoterEndDate}
                  />
                </Col>
                <Col md={12} className="mb-3">
                  <Label>Banner Images</Label>
                  <Dropzone
                    onChangeStatus={handleChangeStatus}
                    accept="image/*" // Specify accepted file types (images in this case)
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Label>Song Upload Fees</Label>
                  <Input
                    type="number"
                    onChange={(e) => setSong_upload_fees(e.target.value)}
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Label>Judge Capped Amount</Label>
                  <Input
                    type="number"
                    onChange={(e) => setJudge_cap_amount(e.target.value)}
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Label>Artist Upload Percent(%)</Label>
                  <Input
                    type="number"
                    value={singer_upload_percent}
                    onChange={handleArtistChange}
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Label>Artist Voting Pool Percent(%)</Label>
                  <Input
                    type="number"
                    value={singer_voting_pool_percent}
                    onChange={(e) =>
                      setsinger_voting_pool_percent(e.target.value)
                    }
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Label>Judge Pool Percent(%)</Label>
                  <Input
                    type="number"
                    value={judge_pool_percent}
                    onChange={(e) => setjudge_pool_percent(e.target.value)}
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Label>Final Artist Voting Pool Percent(%)</Label>
                  <Input
                    type="number"
                    value={final_singer_voting_pool_percent}
                    onChange={(e) =>
                      setfinal_singer_voting_pool_percent(e.target.value)
                    }
                  />
                </Col>
                <Col md={12} className="mb-3">
                  <Label>Artist Capped Amount</Label>
                  <Row>
                    {" "}
                    {singer_cap_amount?.map((data, index) => {
                      console.log(singer_cap_amount, "singer_cap_amount");
                      const isLast = index === singer_cap_amount?.length - 1;
                      return (
                        <>
                          <Col
                            key={index}
                            md={3}
                            sm={4}
                            xl={3}
                            className="mb-3"
                          >
                            <Input
                              type="number"
                              placeholder={
                                isLast ? "Rank 4-10" : `Rank ${data.rank}`
                              }
                              value={data?.percent}
                              onChange={(e) =>
                                handleSingerCap(
                                  index,
                                  "percent",
                                  e.target.value
                                )
                              }
                            />
                          </Col>
                        </>
                      );
                    })}
                  </Row>
                </Col>

                <Col md={12} className="mb-3">
                  <Label>Winning Percent(%)</Label>
                  <Row>
                    {" "}
                    {singer_winner_percent.map((data, index) => (
                      <Col key={index} md={2} sm={3} xl={2} className="mb-3">
                        <Label>Rank {data.rank}</Label>
                        <Input
                          type="number"
                          value={data.percent}
                          onChange={(e) =>
                            handleWinnerChange(index, "percent", e.target.value)
                          }
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>

                <Col md={12} className="mb-3">
                  <Label>Final Artist Capped Amount</Label>
                  <Row>
                    {" "}
                    {final_singer_cap_amount.map((data, index) => (
                      <Col key={index} md={3} sm={4} xl={3} className="mb-3">
                        {/* <Label>Rank {data.rank}</Label> */}
                        <Input
                          type="number"
                          placeholder={
                            index === final_singer_cap_amount.length - 1
                              ? "Rank 4-10"
                              : `Rank ${data.rank}`
                          }
                          value={data.percent}
                          onChange={(e) =>
                            handlefinalSingerCap(
                              index,
                              "percent",
                              e.target.value
                            )
                          }
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>

                <Col md={12} className="mb-3">
                  <Label>Final Winning Percent(%)</Label>
                  <Row>
                    {" "}
                    {final_singer_winner_percent.map((data, index) => (
                      <Col key={index} md={2} sm={3} xl={2} className="mb-3">
                        <Label>Rank {data.rank}</Label>
                        <Input
                          type="number"
                          value={data.percent}
                          onChange={(e) =>
                            handlefinalWinnerChange(
                              index,
                              "percent",
                              e.target.value
                            )
                          }
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit" onClick={AddEvent}>
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* View Modal */}
      <Modal className="fade" size="xl" show={editModal}>
        <Modal.Header>
          <Modal.Title>View {assign ? "Judges" : "Event"}</Modal.Title>
          <Button
            variant="outline-primary"
            onClick={() => {
              setAssgin(!assign);
              Judges();
            }}
          >
            {assign ? "Event" : "Judges"}
          </Button>
        </Modal.Header>
        <Modal.Body>
          {!assign ? (
            <Container>
              <Col md={12}>
                <Row>
                  <Col md={12} className="mb-3">
                    <Label>Event Title</Label>
                    <Input
                      type="text"
                      defaultValue={viewEvent?.title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label>Event Start-End Date</Label>
                    <Input
                      type="text"
                      onChange={handleEventDate}
                      defaultValue={
                        viewEvent?.event_start_date +
                        " ↔ " +
                        viewEvent?.event_end_date
                      }
                    />
                  </Col>
                  <Col md={6} className="pt-4">
                    <RangeDatePicker
                      startText="Start"
                      endText="End"
                      startPlaceholder="Start Date"
                      endPlaceholder="End Date"
                      onChange={handleEventDate}
                      dateFormat="YYYY-MM-DD" // Format to display the date
                      selectedStartDate={eventStartDate} // Pass the selected start date
                      selectedEndDate={eventEndDate} // Pass the selected end date
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label>Artist Start-End Date</Label>
                    <Input
                      type="text"
                      onChange={handleSingerDate}
                      defaultValue={
                        viewEvent?.singer_start_date +
                        " ↔ " +
                        viewEvent?.singer_end_date
                      }
                    />
                  </Col>
                  <Col md={6} className="pt-4">
                    <RangeDatePicker
                      startText="Start"
                      endText="End"
                      startPlaceholder="Start Date"
                      endPlaceholder="End Date"
                      onChange={handleSingerDate}
                      dateFormat="YYYY-MM-DD" // Format to display the date
                      selectedStartDate={singerStartDate} // Pass the selected start date
                      selectedEndDate={singerEndDate} // Pass the selected end date
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label>Judge Start-End Date</Label>
                    <Input
                      type="text"
                      onChange={handleJudgeDate}
                      defaultValue={
                        viewEvent?.judge_start_date +
                        " ↔ " +
                        viewEvent?.judge_end_date
                      }
                    />
                  </Col>
                  <Col md={6} className="pt-4">
                    <RangeDatePicker
                      startText="Start"
                      endText="End"
                      startPlaceholder="Start Date"
                      endPlaceholder="End Date"
                      onChange={handleJudgeDate}
                      dateFormat="YYYY-MM-DD" // Format to display the date
                      selectedStartDate={JudgeStartDate} // Pass the selected start date
                      selectedEndDate={JudgeEndDate} // Pass the selected end date
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label>Voting Start-End Date</Label>
                    <Input
                      type="text"
                      onChange={handleVoterDate}
                      defaultValue={
                        viewEvent?.voter_start_date +
                        " ↔ " +
                        viewEvent?.voter_end_date
                      }
                    />
                  </Col>
                  <Col md={6} className="pt-4">
                    <RangeDatePicker
                      startText="Start"
                      endText="End"
                      startPlaceholder="Start Date"
                      endPlaceholder="End Date"
                      onChange={handleVoterDate}
                      dateFormat="YYYY-MM-DD" // Format to display the date
                      selectedStartDate={VoterStartDate} // Pass the selected start date
                      selectedEndDate={VoterEndDate} // Pass the selected end date
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label>Final Voting Start-End Date</Label>
                    <Input
                      type="text"
                      onChange={handleFinal9Date}
                      defaultValue={
                        viewEvent?.final_voter_start_date +
                        " ↔ " +
                        viewEvent?.final_voter_end_date
                      }
                    />
                  </Col>
                  <Col md={6} className="pt-4">
                    <RangeDatePicker
                      startText="Start"
                      endText="End"
                      startPlaceholder="Start Date"
                      endPlaceholder="End Date"
                      onChange={handleFinal9Date}
                      dateFormat="YYYY-MM-DD" // Format to display the date
                      selectedStartDate={Final9StartDate} // Pass the selected start date
                      selectedEndDate={Final9EndDate} // Pass the selected end date
                    />
                  </Col>
                  <Col md={12} className="mb-3">
                    <Label>Update Banner Images</Label>
                    <Dropzone
                      onChangeStatus={handleChangeStatus}
                      accept="image/*" // Specify accepted file types (images in this case)
                    />{" "}
                    <Row>
                      {viewEvent &&
                        viewEvent?.images?.map((e, i) => {
                          return (
                            <>
                              <Col md={3} sm={3} xs={4}>
                                <div className="position-relative">
                                  <a
                                    href={e.image}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={e.image}
                                      style={{ width: "100%", height: 100 }}
                                      alt=""
                                      className="my-3 img-thumbnail"
                                    />
                                  </a>
                                  <div
                                    className="position-absolute w-100 z-1"
                                    style={{ top: "3%", left: "100%" }}
                                  >
                                    <i
                                      className="fa fa-trash text-danger"
                                      onClick={() => DeleteEventImg(e.id)}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </>
                          );
                        })}
                    </Row>
                  </Col>
                  <Col md={3} className="mb-3">
                    <Label>Song Upload Fees</Label>
                    <Input
                      type="number"
                      onChange={(e) => setSong_upload_fees(e.target.value)}
                      defaultValue={viewEvent?.song_upload_fees}
                    />
                  </Col>
                  <Col md={3} className="mb-3">
                    <Label>Judge Capped Amount</Label>
                    <Input
                      type="number"
                      onChange={(e) => setJudge_cap_amount(e.target.value)}
                      defaultValue={viewEvent?.judge_cap_amount}
                    />
                  </Col>
                  <Col md={3} className="mb-3">
                    <Label>Artist Upload Percent(%)</Label>

                    <Input
                      type="number"
                      defaultValue={viewEvent?.singer_upload_percent}
                      onChange={handleArtistChange}
                    />
                  </Col>
                  <Col md={3} className="mb-3">
                    <Label>Artist Voting Pool Percent(%)</Label>
                    <Input
                      type="number"
                      defaultValue={viewEvent?.singer_voting_pool_percent}
                      onChange={(e) =>
                        setsinger_voting_pool_percent(e.target.value)
                      }
                    />
                  </Col>
                  <Col md={3} className="mb-3">
                    <Label>Judge Pool Percent(%)</Label>
                    <Input
                      type="number"
                      defaultValue={viewEvent?.judge_pool_percent}
                      onChange={(e) => setjudge_pool_percent(e.target.value)}
                    />
                  </Col>
                  <Col md={3} className="mb-3">
                    <Label>Final Artist Voting Pool Percent(%)</Label>
                    <Input
                      type="number"
                      defaultValue={viewEvent?.final_singer_voting_pool_percent}
                      onChange={(e) =>
                        setfinal_singer_voting_pool_percent(e.target.value)
                      }
                    />
                  </Col>
                  <Col md={12} className="mb-3">
                    <Label>Artist Capped Amount</Label>
                    <Row>
                      {" "}
                      {viewEvent?.singer_cap_amount &&
                        JSON.parse(viewEvent?.singer_cap_amount).map(
                          (data, index) => (
                            <Col
                              key={index}
                              md={3}
                              sm={4}
                              xl={3}
                              className="mb-3"
                            >
                              <Label>
                                Rank{" "}
                                {index ===
                                JSON.parse(viewEvent?.final_singer_cap_amount)
                                  .length -
                                  1
                                  ? "4-10"
                                  : data.rank}
                              </Label>
                              <Input
                                type="number"
                                disabled
                                value={data.percent}
                              />
                            </Col>
                          )
                        )}
                    </Row>{" "}
                  </Col>
                  <Col md={12} className="mb-3">
                    <Label>Winner Percent(%)</Label>
                    <Row>
                      {" "}
                      {viewEvent?.singer_winner_percent &&
                        JSON.parse(viewEvent?.singer_winner_percent).map(
                          (data, index) => (
                            <Col
                              key={index}
                              md={2}
                              sm={3}
                              xl={2}
                              className="mb-3"
                            >
                              <Label>Rank {data.rank}</Label>
                              <Input
                                type="number"
                                value={data.percent}
                                disabled
                              />
                            </Col>
                          )
                        )}
                    </Row>
                  </Col>

                  <Col md={12} className="mb-3">
                    <Label>Final Artist Capped Amount</Label>
                    <Row>
                      {" "}
                      {viewEvent?.final_singer_cap_amount &&
                        JSON.parse(viewEvent?.final_singer_cap_amount).map(
                          (data, index) => (
                            <Col
                              key={index}
                              md={3}
                              sm={4}
                              xl={3}
                              className="mb-3"
                            >
                              <Label>
                                Rank{" "}
                                {index ===
                                JSON.parse(viewEvent?.final_singer_cap_amount)
                                  .length -
                                  1
                                  ? "4-10"
                                  : data.rank}
                              </Label>
                              <Input
                                type="number"
                                placeholder={`Rank ${data.rank}`}
                                value={data.percent}
                                disabled
                              />
                            </Col>
                          )
                        )}
                    </Row>{" "}
                  </Col>
                  <Col md={12} className="mb-3">
                    <Label>Final Winner Percent(%)</Label>
                    <Row>
                      {" "}
                      {viewEvent?.final_singer_winner_percent &&
                        JSON.parse(viewEvent?.final_singer_winner_percent).map(
                          (data, index) => (
                            <Col
                              key={index}
                              md={2}
                              sm={3}
                              xl={2}
                              className="mb-3"
                            >
                              <Label>Rank {data.rank}</Label>
                              <Input
                                type="number"
                                value={data.percent}
                                disabled
                              />
                            </Col>
                          )
                        )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Container>
          ) : (
            <Container>
              <Row>
                <Col md={6} className="border border-2">
                  <div className="my-2">
                    <h4 className="text-center">All Judges</h4>
                    <div>
                      {judges
                        .filter(
                          (x) =>
                            !selectedJudges.some(
                              (selectedJudge) => selectedJudge.id === x.id
                            )
                        )
                        .reduce((genreGroups, judge) => {
                          const genreId = judge.genre.id;
                          const existingGroup = genreGroups.find(
                            (group) => group.id === genreId
                          );

                          if (existingGroup) {
                            existingGroup.judges.push(judge);
                          } else {
                            genreGroups.push({ id: genreId, judges: [judge] });
                          }

                          return genreGroups;
                        }, [])
                        .map((group) => (
                          <div key={group.id}>
                            <h5>{group.judges[0]?.genre.name}</h5>
                            {group.judges.map((judge, index) => (
                              <div
                                key={judge.id}
                                className="judge-item selected"
                                onClick={() => handleSelectJudge(judge)}
                              >
                                <h5>
                                  {index + 1}:{judge.fname} {judge.lname} -{" "}
                                  {judge.genre.name}
                                </h5>
                              </div>
                            ))}
                          </div>
                        ))}
                    </div>
                  </div>
                </Col>
                <Col md={6} className="border border-2">
                  <div className="my-2">
                    <h4 className="text-center">Selected Judges</h4>
                    {selectedJudges
                      .reduce((genreGroups, judge) => {
                        const genreId = judge.genre.id;
                        const existingGroup = genreGroups.find(
                          (group) => group.id === genreId
                        );

                        if (existingGroup) {
                          existingGroup.judges.push(judge);
                        } else {
                          genreGroups.push({ id: genreId, judges: [judge] });
                        }

                        return genreGroups;
                      }, [])
                      .map((group) => (
                        <div key={group.id}>
                          <h5>{group.judges[0]?.genre.name}</h5>
                          {group.judges.map((judge, index) => (
                            <div
                              key={judge.id}
                              className="judge-item selected"
                              onClick={() => handleRemoveJudge(judge)}
                            >
                              <h5>
                                {index + 1}:{judge.fname} {judge.lname} -{" "}
                                {judge.genre.name}
                              </h5>
                            </div>
                          ))}
                        </div>
                      ))}
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!assign ? (
            <>
              <Button
                variant="success"
                onClick={() => editEvent(viewEvent?.id)}
              >
                Update
              </Button>
              <Button variant="danger" onClick={() => setDeleteModal(true)}>
                Delete
              </Button>
            </>
          ) : (
            <Button variant="success" onClick={De_AttachJudges}>
              Save
            </Button>
          )}

          <Button
            variant="primary"
            onClick={() => {
              setEditModal(false);
              setAssgin(false);
              navigate("/Event");
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Delete Modal*/}
      <Modal className="fade" size="sm" show={deleteModal}>
        <Modal.Header>
          <Modal.Title>Deleting Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <h6>Are you sure ?</h6>
            <Col md={12} className="mt-3">
              <Label>Password:</Label>
              <Input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Col>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="error" onClick={DeleteEvent}>
            Delete
          </Button>

          <Button
            variant="primary"
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Event;
