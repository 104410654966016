/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { Link } from "react-router-dom";

/// Image
//import profile from "../../../images/profile/pic1.jpg";
//import plus from "../../../images/plus.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector(".heart");
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    handleheartBlast.addEventListener("click", heartBlast);
  }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  const path = window.location.pathname;
  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          {/* <li className={`${path === "/dashboard" ? "mm-active" : ""}`}> */}
          {/* <Link className="" to="/dashboard">
              <i className="flaticon-025-dashboard"></i>
              <span className="nav-text">Dashboard</span>
            </Link> */}
          {/* <ul>
              <li>
                <Link
                  className={`${path === "" ? "mm-active" : "dashboard"}`}
                  to="/dashboard"
                >
                  {" "}
                  Dashboard Light
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "dashboard-dark" ? "mm-active" : ""}`}
                  to="/dashboard-dark"
                >
                  Dashboard Dark
                </Link>
              </li>

              <li>
                <Link
                  className={`${path === "guest-list" ? "mm-active" : ""}`}
                  to="/guest-list"
                >
                  Guest
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "guest-detail" ? "mm-active" : ""}`}
                  to="/guest-detail"
                >
                  Guest Detail
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "concierge" ? "mm-active" : ""}`}
                  to="/concierge"
                >
                  Concierge
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "room-list" ? "mm-active" : ""}`}
                  to="/room-list"
                >
                  Room
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "reviews" ? "mm-active" : ""}`}
                  to="/reviews"
                >
                  Reviews
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "task" ? "mm-active" : ""}`}
                  to="/task"
                >
                  Task
                </Link>
              </li>
            </ul> */}
          {/* </li> */}

          <li
            className={`${
              path === "/" || path === "/dashboard" ? "mm-active" : ""
            }`}
          >
            <Link className="" to="/">
              <i className="fa fa-tv"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>
          <li className={`${path === "/Judge" ? "mm-active" : ""}`}>
            <Link className="" to="/Judge">
              <i className="fa fa-users"></i>
              <span className="nav-text">Judge</span>
            </Link>
          </li>
          <li className={`${path === "/Cheif_Judge" ? "mm-active" : ""}`}>
            <Link className="" to="/Cheif_Judge">
              <i className="fa fa-gavel"></i>
              <span className="nav-text">Cheif-Judge</span>
            </Link>
          </li>
          <li className={`${path === "/singer" ? "mm-active" : ""}`}>
            <Link className="" to="/singer">
              <i className="fa fa-microphone"></i>
              <span className="nav-text">Singer</span>
            </Link>
          </li>
          <li
            className={`${
              path === "/Event" || path === "/Event/:id" ? "mm-active" : ""
            }`}
          >
            <Link className="" to="/Event">
              <i className="fa fa-calendar"></i>
              <span className="nav-text">Event</span>
            </Link>
          </li>
          <li className={`${path === "/Final_List" ? "mm-active" : ""}`}>
            <Link className="" to="/Final_List">
              <i className="fa fa-list"></i>
              <span className="nav-text">Final List</span>
            </Link>
          </li>
          <li className={`${path === "/user" ? "mm-active" : ""}`}>
            <Link className="" to="/user">
              <i className="fa fa-user"></i>
              <span className="nav-text">Voters</span>
            </Link>
          </li>
          <li className={`${path === "/Winner" ? "mm-active" : ""}`}>
            <Link className="" to="/Winner">
              <i className="fa fa-trophy"></i>
              <span className="nav-text">Winners</span>
            </Link>
          </li>
        </MM>
        <div className="copyright">
          <p>
            <strong>Final 9</strong> © 2021 All Rights Reserved
          </p>
          <p className="fs-12">
            Made with <span className="heart"></span> by HnHTechSolutions
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
