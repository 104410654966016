import React, { useEffect, useState } from "react";
import { GlobalFilter } from "./GlobalFilter";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";
import { axiosInstance } from "../../../../services/AxiosInstance";
export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};
export const User = () => {
  const keys = ["id", "fname", "lname", "email"];
  const [loader, setLoader] = useState(false);
  const [data, setdata] = useState([]);
  const COLUMNS = [
    ...keys.map((key) => ({
      Header:
        key === "fname"
          ? "First Name"
          : key === "lname"
          ? "Last Name"
          : key.replace(/_/g, " "),
      Footer:
        key === "fname"
          ? "First Name"
          : key === "lname"
          ? "Last Name"
          : key.replace(/_/g, " "),
      accessor: key,
      Filter: ColumnFilter,
    })),
    {
      Header: "Image",
      Footer: "Image",
      accessor: "image",
      Cell: ({ value }) => {
        return (
          <>
            <a href={value} target="_blank" rel="noreferrer">
              <img
                src={value}
                alt="no img Found"
                className="rounded"
                width={40}
                height={40}
              />
            </a>
          </>
        );
      },
    },
    // {
    //   Header: "status",
    //   Footer: "status",
    //   accessor: "status",
    //   Cell: ({ row }) => {
    //     const update = (id) => {
    //       setLoader(true);
    //       axiosInstance
    //         .put(`admin/singers/activate/${id}`)
    //         .then((response) => {
    //           if (response.data.status === true) {
    //             toast.success(response.data.response);
    //             GetSinger();
    //             setLoader(false);
    //           } else {
    //             toast.error(response.data.error);
    //             setLoader(false);
    //           }
    //         })
    //         .catch((error) => {
    //           console.log(error);
    //           setLoader(false);
    //         });
    //     };
    //     return (
    //       <>
    //         <button
    //           className={`badge ${
    //             row.original.status === 1 ? "bg-success" : "bg-danger"
    //           }`}
    //           onClick={() => update(row.original.id)}
    //         >
    //           {row.original.status === 1 ? "Active" : "De-Active"}
    //         </button>
    //       </>
    //     );
    //   },
    // },
  ];

  const [columns] = useState(COLUMNS);
  
  useEffect(() => {
    setLoader(true)
    axiosInstance
      .get("/admin/voters")
      .then((res) => {
        setdata(res?.data?.data)
        console.log(res, "voter");
        setLoader(false)
      })
      .catch((err) => {
        console.log(err)
      });
  }, []);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;
  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="card">
        <div>
          <div className="card-header">
            <h4 className="card-title float-left">Voter</h4>
          </div>
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table
              {...getTableProps()}
              id="table-to-export" // Add the id attribute
              className="table display"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* {column.canFilter ? column.render("Filter") : null} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
              {""}
            </span>
            <span className="table-index">
              Go to page :{" "}
              <input
                type="number"
                className="ml-2"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </span>
          </div>
          <div className="text-center">
            <div className="filter-pagination  mt-3">
              <button
                className=" previous-button"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>

              <button
                className="previous-button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous
              </button>
              <button
                className="next-button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </button>
              <button
                className=" next-button"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
