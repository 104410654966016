function PlayerControl({ isPlaying, setIsPlaying, PlayingId }) {
  return (
    <div className="c-player--controls">
      <button className="play-btn" onClick={() => setIsPlaying(PlayingId)}>
        {isPlaying ? (
          <i className="fa fa-pause" />
        ) : (
          <i className="fa fa-play" />
        )}
      </button>
    </div>
  );
}

export default PlayerControl;
