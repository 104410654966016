import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../services/AxiosInstance";
import PlayerIndex from "./Player";
import { toast } from "react-toastify";

const FinalListDetails = () => {
  const [loader, setLoader] = useState(false);
  const [song, setSong] = useState([]);
  const [selectedsong, setSelectedSong] = useState(0);
  const { id } = useParams();
  const [playingIndex, setPlayingIndex] = useState(null);
  const getFinalsong = useCallback(() => {
    setLoader(true);
    axiosInstance
      .get(`admin/events/${id}/songs`)
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          setLoader(false);
          setSong(response.data.data);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  }, [id]);
  const Publish = () => {
    setLoader(true);
    try {
      axiosInstance
        .put(`admin/events/${id}/publish_list`)
        .then((response) => {
          console.log(response);
          if (response.data.status === true) {
            getFinalsong();
            toast.success(response.data.response);
            setLoader(false);
          } else {
            setLoader(false);
            toast.error(response.data.error);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.success(error.response.data.erro);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getFinalsong();
  }, [getFinalsong]);

  const uniqueGenres = new Set();

  const uniqueGenreArray = song.reduce((result, e, index, array) => {
    const genre = e.judge_selected_song.judge_song.song.genre.name;

    // Check if the genre is already in the set
    if (!uniqueGenres.has(genre)) {
      uniqueGenres.add(genre); // Add the genre to the set
      result.push(genre);
    }

    return result;
  }, []);

  const uniqueGenreString = uniqueGenreArray.join("/");

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="container">
        <div className="row">
          <div className="row">
            <div className="col-md-2">
              <img
                src={
                  song[0]?.judge_selected_song?.judge_song?.song?.event
                    ?.images[0]?.image
                }
                alt=""
                className="img-fluid rounded-circle mb-2"
              />
            </div>
            <div className="col-md-9">
              <h1>
                {song[0]?.judge_selected_song?.judge_song?.song?.event?.title}
              </h1>
              <h2 className="badge bg-light">{uniqueGenreString}</h2>
              <h5>{song.length} Songs</h5>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-6">
              <div className="border border-1 rounded bg-white shadow">
                {song.map((e, index) => (
                  <div
                    className={`border border-1 mb-3 p-3 rounded c-pointer  ${
                      index === selectedsong ? "shadow-lg bg-primary " : ""
                    }`}
                    onClick={() => setSelectedSong(index)}
                  >
                    <h3
                      className={index === selectedsong ? "text-white" : ""}
                    >{`${index + 1}.${
                      e.judge_selected_song.judge_song.song.title
                    }`}</h3>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6">
              {song.map((e, index, array) => {
                return (
                  <>
                    {index === selectedsong ? (
                      <div>
                        <div className="float-end">
                          {index !== null && (
                            <div>
                              {index === 0 ? (
                                <button
                                  className="btn btn-primary"
                                  onClick={() => setSelectedSong(index + 1)}
                                >
                                  <i className="fa fa-arrow-circle-right" />
                                </button>
                              ) : index === array.length - 1 ? (
                                <button
                                  className="btn btn-primary"
                                  onClick={() => setSelectedSong(index - 1)}
                                >
                                  <i className="fa fa-arrow-circle-left" />
                                </button>
                              ) : (
                                <>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => setSelectedSong(index - 1)}
                                  >
                                    <i className="fa fa-arrow-circle-left" />
                                  </button>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => setSelectedSong(index + 1)}
                                  >
                                    <i className="fa fa-arrow-circle-right" />
                                  </button>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                        <PlayerIndex
                          img={e.judge_selected_song.judge_song.song.thumbnail}
                          name={e.judge_selected_song.judge_song.song.title}
                          genre={
                            e.judge_selected_song.judge_song.song.genre.name
                          }
                          event={
                            e?.judge_selected_song?.judge_song?.song?.event
                              ?.title
                          }
                          audio={e.judge_selected_song.judge_song.song.song}
                          PlayingId={index}
                          isPlaying={index === playingIndex}
                          updatePlayingIndex={(i) =>
                            setPlayingIndex(i === playingIndex ? null : i)
                          }
                        />
                      </div>
                    ) : null}
                  </>
                );
              })}
            </div>
          </div>
          {song.some((s) => s.admin_is_published === 0) && (
            <div>
              <button
                className="btn btn-primary mt-2 w-25 float-end"
                onClick={Publish}
              >
                Publish
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FinalListDetails;
