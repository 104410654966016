/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";

//import './table.css';
import "./filtering.css";

import Swal from "sweetalert2";
import { axiosInstance } from "../../../../services/AxiosInstance";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export const GlobalFilter = ({ filter, setFilter, paynow }) => {
  return (
    <div className="row">
      <div className="col-md-6">
        Search :{" "}
        <input
          className="ml-2 input-search form-control"
          value={filter || ""}
          onChange={(e) => setFilter(e.target.value)}
          style={{ width: "50%" }}
        />
      </div>
      <div className="col-md-6">
        <div className="btn btn-primary float-end" onClick={paynow}>
          Pay Now
        </div>
      </div>
    </div>
  );
};

export const Winners = () => {
  const keys = [
    "rank",
    "singer",
    "singer_email",
    "song_winning_amount",
    'total_votes',
    "judge",
    "judge_email",
    "judge_winning_amount",
  ];
  const { id, type } = useParams("");
  const COLUMNS = [
    ...keys.map((key) => ({
      Header: key.replace(/_/g, " "),
      Footer: key.replace(/_/g, " "),
      accessor: key,
      Filter: ColumnFilter,
    })),
  ];

  const [columns] = useState(COLUMNS);

  const [data, setData] = useState([]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;
  const [loader, setLoader] = useState(false);

  const GetWinners = () => {
    setLoader(true);
    axiosInstance
      .get(`admin/events/${id}/${type}`)
      .then(function (response) {
        console.log(response, "Getting Compaines");
        if (response?.data.status === true) {
          setData(response?.data.data);
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  useEffect(() => {
    GetWinners();
  }, [type]);
  const paynow = () => {
    setLoader(true);
    axiosInstance
      .post(`admin/events/${id}/${type}/pay`)
      .then(function (response) {
        if (response?.data.status === true) {
          setLoader(false);
          GetWinners();
          toast.success(response.data.message);
        } else {
          setLoader(false);
          toast.error(response.data.message);
        }
      })
      .catch(function (error) {
        setLoader(false);
        toast.error(error);
      });
  };

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="card">
        <div>
          <div className="card-header">
            <h4 className="card-title float-left">
              {type === "winners" ? "First 9" : "Final 9"}
            </h4>
            {type === "winners" ? (
              <Link
                className="btn btn-primary"
                to={`/Rank/${id}/final_winners`}
              >
                View Final 9
              </Link>
            ) : (
              <Link className="btn btn-primary" to={`/Rank/${id}/winners`}>
                View First 9
              </Link>
            )}
          </div>
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              paynow={paynow}
            />
            <table
              {...getTableProps()}
              id="table-to-export" // Add the id attribute
              className="table display"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* {column.canFilter ? column.render("Filter") : null} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
              {""}
            </span>
            <span className="table-index">
              Go to page :{" "}
              <input
                type="number"
                className="ml-2"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </span>
          </div>
          <div className="text-center">
            <div className="filter-pagination  mt-3">
              <button
                className=" previous-button"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>

              <button
                className="previous-button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous
              </button>
              <button
                className="next-button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </button>
              <button
                className=" next-button"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Winners;
